<template>
  <el-dialog
    title="身份验证信息"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <img class="img" :src="downloadUrl + modalData.front" alt="" />

    <img
      class="img"
      mode="aspectFit"
      :src="downloadUrl + modalData.back"
      alt=""
    />

    <template #footer>
      <el-button @click="closeModal"> 关闭 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { downloadUrl } from "@/config/env";
export default {
  mixins: [modalMixin],
  props: ["modalData"],
  data() {
    return {
      formName: "form",
      downloadUrl: downloadUrl,
    };
  },
};
</script>

<style scoped>
.img {
  max-width: 80%;
  max-height: 300px;
  display: block;
  text-align: center;
  margin: 10px auto;
}
</style>